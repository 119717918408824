<template>
    <v-card outlined rounded max-width="900" class="mx-auto">
        <v-card-title>
            {{ this.product.productName }}
        </v-card-title>
        <v-card-subtitle>{{ product.productCode }}</v-card-subtitle>
        <v-divider />
        <v-card-text>
            <v-row align="center">
                <v-col cols="3">
                    <v-img v-if="product.imageUrl" :src="product.imageUrl" height="125" contain
                        position="center center" />
                    <v-img v-else src="@/assets/imgnotfound.jpg" height="125" contain position="center center" />
                </v-col>
                <v-col cols="8" offset="1">
                    <v-row>
                        <v-col cols="6">
                            <v-text-field :label="$t('PriceAgreements.Valid_from')" readonly class="no-underline"
                                hide-details :value="$d(new Date(product.validFrom), 'short')" />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field :label="$t('PriceAgreements.Valid_thru')" readonly class="no-underline"
                                hide-details :value="$d(new Date(product.validUntil), 'short')" />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field :label="$t('Minimum_quantity')" :value="product.minimumQuantity" readonly
                                class="no-underline" hide-details />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field :label="$t('Unit_price')"
                                :value="product.unitPrice ? $n(product.unitPrice, 'decimal') + ' ' + product.currency : 'op aanvraag'"
                                readonly class="no-underline" hide-details />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <cart-item-toolbar :productCode="product.productCode" :minimalOrderQty="product.unitsInBox" />
        </v-card-actions>
    </v-card>
</template>
<script>
import CartItemToolbar from "./CartItemToolbar.vue";
export default {
    name: "PriceAgreementCard",
    components: { CartItemToolbar },
    props: {
        product: Object,
    },
}

</script>
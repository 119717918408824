<template>
	<div>
		<v-toolbar flat dark color="primary" class="sticky-top" height="80">
			<v-toolbar-title>{{ $t('SmartNavBar.My_price_agreements') }}</v-toolbar-title>

			<v-text-field v-model="searchValue" :placeholder="$t('Search')" append-icon="mdi-magnify" flat hide-details
				clearable solo light dense style="width: 500px; max-width: 96vw" class="kmp">
			</v-text-field>
		</v-toolbar>

		<v-container fluid>
			<v-data-iterator :items="priceAgreementList" item-key="rowIdent" hide-default-footer disable-pagination
				:search="searchValue">
				<template v-slot:default="{ items, isExpanded, expand }">
					<v-row justify="center" v-for="priceAgreement in items" :key="priceAgreement.rowIdent">
						<v-col cols="12">
							<PriceAgreementCard :product="priceAgreement" />
						</v-col>
					</v-row>
				</template>
			</v-data-iterator>
		</v-container>
	</div>
</template>

<script>
import caas from "@/services/caas";
import PriceAgreementCard from "@/components/PriceAgreementCard";
import { mapGetters } from "vuex";
export default {
	name: "PriceAgreements",
	components: { PriceAgreementCard },
	computed: {
		...mapGetters({
			contentHeight: "session/contentHeight",
			customerId: "session/customerId",
			locale: "session/locale"
		}),
	},
	data() {
		return {
			loading: false,
			searchValue: "",
			priceAgreementList: [],
		};
	},
	methods: {
		getPriceAgreementList() {
			this.loading = true;
			caas
				.rpc('getPortalUserPriceAgreements', { languageCode: this.locale })
				.then((response) => {
					this.priceAgreementList =
						response.data.success.data.priceAgreement;
				})
				.catch((error) => {
					console.error(error)
				})
				.finally(() => {
					this.loading = false;
				})
		},
	},
	created() {
		this.getPriceAgreementList()
	},
};
</script>
